<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
      <v-list-item-subtitle>
        {{ formattedItem.subtitle2 }}
        <v-chip
          v-if="formattedItem.estado === RONDA.estados.enCurso"
          small
          label
          color="warning"
          class="ml-1"
        >
          En curso
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { stringToHslColor } from '@/utils/ui'
import { calculateMd5 } from '@/utils/crypto'
import { RONDA } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      RONDA,
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = `${this.$options.filters.shortDate(item.fcomienzo)} - ${item.cliente_nombre}`
      item.subtitle = `${item.puesto_servicio_descripcion} - ${item.servicio_descripcion}`
      if (item.ffin) {
        item.subtitle2 = `
          ${item.mronda_descripcion}
          de ${this.$options.filters.shortTime(item.fcomienzo)}
          a ${this.$options.filters.shortTime(item.ffin)}`
      } else {
        item.subtitle2 = `${item.mronda_descripcion} desde las ${this.$options.filters.shortTime(item.fcomienzo)}`
      }
      item.avatar = this.$options.filters.initials(item.cliente_nombre)
      item.avatarColor = stringToHslColor(calculateMd5(item.idmronda.toString()))
      return item
    }
  }
}
</script>
